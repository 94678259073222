import React from 'react';
import { Layout, SectionLocation } from '@websites/components';
import { YellowBusinessContext } from '../contexts/YellowBusinessContext';

function LocationPage() {
  const { yellowBusiness, sections } = React.useContext(YellowBusinessContext);

  const hasTheme = !!yellowBusiness?.website?.theme;

  return (
    <>
      {hasTheme && (
        <Layout
          yellowBusiness={yellowBusiness}
          sections={sections}
          pageName={sections?.locationSection?.name}
        >
          <SectionLocation
            section={sections?.locationSection}
            yellowBusiness={yellowBusiness}
          />
        </Layout>
      )}
    </>
  );
}

export default LocationPage;
